import React from 'react';
import me from './me.png';
import linkedinLogo from './LinkedIn.png'; // Import LinkedIn logo
import githubLogo from './GitHub.png'; // Import GitHub logo

const NavBar = () => {
  return (
    <div className="w-full bg-gunmetal mx-auto relative">
      <div className="overlay"></div> {/* Add overlay */}
      <h1 className="text-3xl text-center text-gunmetal font-bold mt-6 mb-4 relative z-20 title-with-shadow">
       Showcase
      </h1>

      <nav className="relative bg-skyblue z-10 flex flex-col sm:flex-row justify-between items-center py-4 px-6">
        <div className="flex items-center space-x-4">
          <img src={me} alt="Profile Img" className="h-16 w-16 rounded-full mr-2" />
          <div>
            <span className="text-xl text-gunmetal font-bold mr-2">Jerold Weller</span>
            <br className="hidden sm:inline" />
            <span className="text-sm text-gray-500">Full Stack MERN Developer</span>
          </div>
        </div>
        {/* Social links */}
        <div className="flex items-center space-x-4">
          <a href="https://www.linkedin.com/in/jeroldweller/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinLogo} alt="LinkedIn" className="h-4" />
          </a>
          <a href="https://github.com/jtweller" target="_blank" rel="noopener noreferrer">
            <img src={githubLogo} alt="GitHub" className="h-6" />
          </a>
        </div>
      </nav>
      <div className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden pointer-events-none z-0">
        <svg
          className="absolute top-0 right-0 left-0 bottom-0 overflow-visible pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            className="wave"
            fill="#87CEEB"
            fillOpacity="1"
            d="M0,128L48,144C96,160,192,192,288,170.7C384,149,480,75,576,85.3C672,96,768,192,864,213.3C960,235,1056,181,1152,138.7C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
               {/* White border line along the top */}
               <path
            className="wave"
            stroke="#FFFFFF" // Set stroke color to white
            strokeWidth="2" // Adjust border thickness as needed
            fill="none"
            d="M0,128L48,144C96,160,192,192,288,170.7C384,149,480,75,576,85.3C672,96,768,192,864,213.3C960,235,1056,181,1152,138.7C1248,96,1344,64,1392,48L1440,32"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default NavBar;











