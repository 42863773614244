import React from 'react';
import NavBar from './NavBar'; // Import the NavBar component
import { BrowserRouter as Router } from 'react-router-dom'; // Assuming you are using React Router
import bgImg from './dbg.png'; // Import the bg image

function App() {
  return (
    <Router>
      <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center">
        <NavBar /> {/* Replace the existing header with the NavBar component */}
        <div className="flex flex-col justify-start items-center min-h-screen bg-cover bg-center w-full" style={{backgroundImage: `url(${bgImg})`}}>
        <div className="max-w-4xl mt-4"> {/* Set the maximum width here */}
            <section className="mb-8 ml-4 mr-4">
              <h2 className="text-2xl text-white font-bold mb-4">About Me</h2>
              <p className="text-lg text-gray-400">
                Welcome to my profile page showcasing my coding apps. I'm passionate about coding and enjoy building various web applications.
              </p>
            </section>
          </div>
          <section className="mb-8">
            <h2 className="text-2xl text-white font-bold mb-4">Live Apps</h2>
            <ul className="list-disc list-inside">
              <li>
                <a href="https://onserve.vercel.app" target="_blank" rel="noreferrer" className="text-white hover:underline">OnServe</a>
              </li>
              <li>
                <a href="https://auth-api-wheat.vercel.app" target="_blank" rel="noreferrer" className="text-white hover:underline">authAccess</a>
              </li>
              {/* Add more live apps as needed */}
            </ul>
          </section>
          <section className='ml-2'>
            <h2 className="text-2xl text-white font-bold mb-4">GitHub Profile</h2>
            <p className="text-lg text-gray-400">
              You can explore the code of my projects on GitHub. Visit my profile <a href="https://github.com/jtweller" className="text-white hover:underline">here</a>.
            </p>
          </section>
        </div>
        <div className="absolute bottom-0 right-0 p-4 bg-none text-right text-gray-600">
          <p>Background Stock photos by <a href="https://www.vecteezy.com/free-photos/background" className="text-blue-500 hover:underline">Vecteezy</a>.</p>
        </div>
        <footer className="bg-white p-4 w-full mt-auto">
          <p className="text-center text-gray-600">© 2024 Jerold Weller. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;

